










import { Vue, Component, Watch } from 'vue-property-decorator'

import { ServerState, ErgState, Erg } from 'shared/state'
import ViewportSize from 'display/ViewportSize'

import { config, GlobalConfig } from 'display/config'
import { ViewportSizeModule } from 'display/ViewportSize'
import { displayType } from 'shared/util'
import { version } from '../package.json'
import globalCssVars from './cssVars'

import ServerRace from 'shared/components/ServerRace.vue'
import ServerPenConn from 'shared/components/ServerPenConn.vue'
import ConfigModal from 'display/components/ConfigModal.vue'
import { Server } from 'shared/state'
import { RemoteIdentify, ClientType } from 'shared/types/config-server'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'

import { RemoteControl, RemoteState, RemoteScreen } from 'shared/state/RemoteControl'
import { RemoteErgRaceState } from 'shared/types/erg/index'
import { getCookie } from 'shared/util/cookie'

@Component({ components: { ServerRace, ServerPenConn, ConfigModal } })
export default class App extends Vue {
	@RemoteState() screens!: Record<string, RemoteScreen>
	@RemoteState() screenOptions!: string[]
	@LogBookUserState()

	@ServerState('state') serverState
	@ErgState() definition
	@GlobalConfig() lang
	@GlobalConfig() paceraceLockScore
	@GlobalConfig() devMode
	@GlobalConfig() route

	config = config.vals
	viewport = ViewportSize
	version = version
	LB = LogBookUser

	setRemoteId() {
		this.$router.onReady(() => {
			if (this.config.global.remoteId && this.$route.name) {
				config.setRemoteId(this.config.global.remoteId, displayType(this.$route.name))
			}
		})
	}
	get cssVars() {
		return globalCssVars()
	}

	created() {
		this.$keybindings.onKeydown(' ', ['Ctrl'], () => this.$root.$emit('toggle-config'))
		if (getCookie('logbooktoken')) {
			LogBookUser.getUser()
		}
		LogBookUser.getRaceServers()
	}

	@Watch('route')
	changeRoute() {
		if (this.$route.name !== this.route) {
			this.$router.push({ name: this.route })
		}
	}
}
