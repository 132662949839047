







import { Vue, Component, Prop } from 'vue-property-decorator'

import Login from './Login.vue'
import Config from 'shared/components/config/_Config.vue'
import { config } from 'display/config'
import { displayType } from 'shared/util'
import { RemoteControl } from 'shared/state/RemoteControl'

@Component({ components: { Config, Login }})
export default class ConfigModal extends Vue {

	config = config
	loggedIn = false

	get page() {
		return displayType(this.$route.name!)
	}

	toggle() {
		const modal = this.$refs.modal as any
		if(modal.visible) {
			modal.hide()
		} else {
			modal.show()
		}
	}

	created() {
		this.$root.$on('toggle-config', this.toggle)
		this.loggedIn = !!localStorage.getItem('ERGLoggedIn')
	}

	destroyed() {
		this.$root.$off('toggle-config', this.toggle)
	}

	backToPen() {
		RemoteControl.remoteErgAction('holding_pen')
	}
}
